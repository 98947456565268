import { translate } from 'react-i18next';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ValidationInput from './elements/ValidationInput';
import Checkbox from './elements/Checkbox';

class SubmitEmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = { submitDisabled: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  mapInputs = inputs => ({
    email: inputs.email,
    viewEmail: inputs.viewEmail,
  });

  render() {
    const {
      t,
      title,
      subTitle,
      buttonText,
      buttonClass,
      inputTitle,
      autoFocus,
      additionalInfo,
      buttonId,
      showEmail,
      activateRequest
    } = this.props;

    return (
      <div>
        <h1>{title}</h1>
        <p>{subTitle}</p>
        <p>{additionalInfo}</p>
        <Formsy
          className="grid--5 form--full-width"
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          {!activateRequest && (
            <ValidationInput
              autoFocus={autoFocus}
              id="email"
              maxLength="80"
              name="email"
              placeholder={inputTitle}
              type="email"
              required
              value=""
              validations="isEmail"
              validationErrors={{
                isDefaultRequiredValue: t('This field is required.'),
              }}
              validationError={t('Please enter a valid email address.')}
              title={inputTitle}
            />
          )}

          {showEmail && (
            <div>
              <Checkbox id="viewEmail" label={t('Generate Locally')} name="viewEmail" value="true" />
              <div className="note">
                {t(
                  "Generating locally means the generated token that's attached to the end user's account will be presented to you so you can provide the token URL to the end user.",
                )}
              </div>
            </div>
          )}
          <div className="form-buttons">
            <button type="submit" className={buttonClass} id={buttonId} disabled={this.state.submitDisabled}>
              {buttonText}
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}

SubmitEmailForm.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  subTitle: PropTypes.string,
  additionalInfo: PropTypes.string,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonClass: PropTypes.string,
  inputTitle: PropTypes.string,
  autoFocus: PropTypes.bool,
  buttonId: PropTypes.string.isRequired,
  showEmail: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  activateRequest: PropTypes.bool,
};

SubmitEmailForm.defaultProps = {
  subTitle: null,
  buttonClass: 'cta cta--secondary',
  inputTitle: 'Email',
  autoFocus: null,
  additionalInfo: null,
  showEmail: false,
  activateRequest: false,
};
export default translate()(SubmitEmailForm);
