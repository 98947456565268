import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { searchUsers, updateUser } from '../redux/actions/userActions';
import SubmitEmailForm from '../components/forms/SubmitEmailForm';
import LoaderOverlay from '../components/LoaderOverlay';
import Message from '../components/Message';
import { translateArray } from '../common/Util';
import { MessageType } from '../common/Constants';


export class ActivateUser extends Component {
  constructor(props) {
    super(props);
    this.userNotFoundMessage = ['Email address not found.', 'Please try again.'];
  }

  componentDidMount() {
    document.title = `Tableau - ${this.props.t('Activate User')}`;
    if (this.props.emailQueryString) {
      this.props.findUser({
        email: this.props.emailQueryString,
        emailChangeHistory: false,
      });
    }
  }

  render() {
    const {
      i18n,
      user,
      success,
      findUser,
      notFound,
      searchError,
      searchRequestId,
      isFetching,
      activateUser,
      updateSuccess,
      updateUserError,
      updateUserRequestId,
      t
    } = this.props;
    const isAlreadyUpdatedError = error => error[0].includes("already");
    const unAuthorizedError = error => error[0].includes("You are not authorized to access the requested page or URL.") || error[0].includes("expired");

    const findByEmail = (formData) => {
      findUser({ email: formData.email, emailChangeHistory: false });
    };
    const onSubmit = () => {
      activateUser(user.uid, {emailVerified: true}, i18n.language);
    };

    return (
      <section className="section">
        <div className="content-container" id="loading-overlay-content">
          {isFetching && <LoaderOverlay/>}
          {notFound && !searchError && (
            <Message
              id="userNotFound"
              text={translateArray(this.userNotFoundMessage)}
              messageType={MessageType.error}
            />
          )}
          {!user && !success && !searchError && (
            <SubmitEmailForm
              autoFocus
              id="emailForm"
              title={t('Activate User')}
              buttonText={t('Next')}
              buttonClass="cta cta--secondary cta--arrow"
              inputTitle={t('New User Email')}
              onSubmit={findByEmail}
              noValidate
              value=""
              buttonId="activateUserNextButton"
              isFetching={isFetching}
              activateRequest={false}
            />
          )}

          {user && user.status && user.status==='active' && (<
            Message id="userActiveMessage" text={"User " + user.email + " is already Active."} messageType={MessageType.warning}
          />)
          }
          {!updateUserError && user && !updateSuccess && user.status && user.status!=='active' && (
            <SubmitEmailForm
              autoFocus
              id="activateUserForm"
              title={t('Activate User')}
              subTitle={`${t('Name')}: ${user.fullName}`}
              additionalInfo={`${t('Email')}: ${user.email}`}
              buttonText={t('Activate User')}
              buttonId="activateUserButton"
              onSubmit={onSubmit}
              noValidate
              isFetching={isFetching}
              activateRequest={true}
            />
          )}

          {updateSuccess && <Message id="successMessage" text={"User, " + user.email + ", has been activated successfully."} messageType={MessageType.notice}/>}
          {searchError && !unAuthorizedError(searchError) && (<>
            <Message id="errorMessage" text={translateArray(searchError)} messageType={MessageType.error}/>
            <div id="searchRequestId">Request ID: {searchRequestId}</div>
          </>)}
          {updateUserError && !unAuthorizedError(updateUserError) && !isAlreadyUpdatedError(updateUserError) && (<>
            <Message
              id="errorMessage"
              text={translateArray(updateUserError)}
              messageType={MessageType.error}
            />
            <div id="updateUserRequestId">Request ID: {updateUserRequestId}</div>
          </>)}
          {((searchError && unAuthorizedError(searchError))||(updateUserError && unAuthorizedError(updateUserError))) && (<>
            <Message id="unAuthorizedError" text={'Something went wrong. Please try again.'}
                     messageType={MessageType.error}/>
            <div id="RequestId">Request ID: {updateUserRequestId || searchRequestId}</div>
          </>)}
        </div>
        <input
          type="hidden"
          id="updateUserRequestId"
          value={updateUserRequestId}
        />
        <input type="hidden" id="searchRequestId" value={searchRequestId}/>
      </section>
    );
  }
}

ActivateUser.propTypes = {
  i18n: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  t: PropTypes.func.isRequired,
  user: PropTypes.object,
  success: PropTypes.bool,
  findUser: PropTypes.func.isRequired,
  activateUser: PropTypes.func.isRequired,
  notFound: PropTypes.bool,
  searchError: PropTypes.array,
  searchRequestId: PropTypes.string,
  updateSuccess: PropTypes.bool,
  updateUser: PropTypes.func.isRequired,
  updateUserError: PropTypes.array,
  updateUserRequestId: PropTypes.string,
  emailQueryString: PropTypes.string,
};

ActivateUser.defaultProps = {
  isFetching: false,
  user: null,
  success: false,
  notFound: false,
  searchError: null,
  searchRequestId: '',
  updateSuccess: false,
  updateUserError: null,
  updateUserRequestId: '',
  emailQueryString: null,
};

export const mapStateToProps = state => ({
  user: state.userSearchReducer.userModel,
  isFetching:
    state.userSearchReducer.isFetching || state.userUpdateReducer.isFetching,
  success: state.userSearchReducer.success,
  searchError: state.userSearchReducer.errorMessage,
  notFound: state.userSearchReducer.notFound,
  emailQueryString: state.locationReducer.email,
  searchRequestId: state.userSearchReducer.requestId,
  updateSuccess: state.userUpdateReducer.success,
  updateUserError: state.userUpdateReducer.errorMessage,
  updateUserRequestId: state.userUpdateReducer.requestId,
});

const mapDispatchToProps = dispatch => ({
  findUser: bindActionCreators(searchUsers, dispatch),
  activateUser: bindActionCreators(updateUser, dispatch)
});
export default translate()(connect(mapStateToProps, mapDispatchToProps)(ActivateUser));
