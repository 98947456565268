import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { searchUsers, unblockUser } from '../redux/actions/userActions';
import SubmitEmailForm from '../components/forms/SubmitEmailForm';
import LoaderOverlay from '../components/LoaderOverlay';
import Message from '../components/Message';
import { translateArray } from '../common/Util';
import { MessageType } from '../common/Constants';


export class UnblockUser extends Component {
  constructor(props) {
    super(props);
    this.userNotFoundMessage = ['Email address not found.', 'Please try again.'];
  }

  componentDidMount() {
    document.title = `Tableau - ${this.props.t('Unblock User')}`;
    if (this.props.emailQueryString) {
      this.props.findUser({
        email: this.props.emailQueryString,
        emailChangeHistory: false,
      });
    }
  }

  render() {
    const {
      user,
      findUser,
      notFound,
      success,
      searchError,
      searchRequestId,
      unblockUser,
      isFetching,
      unblockSuccess,
      unblockUserError,
      unblockUserRequestId,
      t
    } = this.props;
    const notUnblockedError = error => error[0].includes("not blocked.");
    const unAuthorizedError = error => error && (error[0].includes("You are not authorized to access the requested page or URL.") || error[0].includes("expired"));
    const findByEmail = (formData) => {
      findUser({ email: formData.email, emailChangeHistory: false });
      debugger
    };
    const onSubmit = () => {
      unblockUser(user.email);
    };

    return (
      <section className="section">
        <div className="content-container" id="loading-overlay-content">
          {isFetching && <LoaderOverlay/>}
          {notFound && !searchError && (
            <Message
              id="userNotFound"
              text={translateArray(this.userNotFoundMessage)}
              messageType={MessageType.error}
            />
          )}
          {!user && !success && !searchError && (
            <SubmitEmailForm
              autoFocus
              id="emailForm"
              title={t('Unblock User')}
              buttonText={t('Next')}
              buttonClass="cta cta--secondary cta--arrow"
              inputTitle={t('User Email')}
              onSubmit={findByEmail}
              noValidate
              value=""
              buttonId="unblockUserNextButton"
              isFetching={isFetching}
              activateRequest={false}
            />
          )}
          {user && success && !unblockUserError && !unblockSuccess && (
            <SubmitEmailForm
              autoFocus
              id="unblockUserForm"
              title={t('Unblock User')}
              subTitle={`${t('Name')}: ${user.fullName}`}
              additionalInfo={`${t('Email')}: ${user.email}`}
              buttonText={t('Unblock User')}
              buttonId="unblockUserButton"
              onSubmit={onSubmit}
              noValidate
              isFetching={isFetching}
              activateRequest={true}
            />
          )}

          {unblockSuccess && <Message id="successMessage" text={"User, " + user.email + ", has been unblocked successfully."} messageType={MessageType.notice}/>}

          {searchError && !unAuthorizedError(searchError) && (<>
            <Message id="errorMessage" text={translateArray(searchError)} messageType={MessageType.error}/>
            <div id="searchRequestId">Request ID: {searchRequestId}</div>
          </>)}
          {unblockUserError && !unAuthorizedError(unblockUserError) && notUnblockedError(unblockUserError) && (<>
            <Message
              id="errorMessage"
              text={translateArray(unblockUserError)
                .replace('$email', user.email)}
              messageType={MessageType.warning}
            />
          </>)}
          {unblockUserError && !unAuthorizedError(unblockUserError) && !notUnblockedError(unblockUserError) && (<>
            <Message
              id="errorMessage"
              text={translateArray(unblockUserError)}
              messageType={MessageType.error}
            />
          </>)}
          {((searchError && unAuthorizedError(searchError)) || (unblockUserError && unAuthorizedError(unblockUserError))) && (<>
            <Message id="unAuthorizedError" text={'Something went wrong. Please try again.'}
                     messageType={MessageType.error}/>
            <div id="RequestId">Request ID: {unblockUserRequestId}</div>
          </>)}
        </div>
        <input type="hidden" id="unblockUserRequestId" value={unblockUserRequestId}/>
        <input type="hidden" id="searchRequestId" value={searchRequestId}/>
      </section>
    );
  }
}

UnblockUser.propTypes = {
  isFetching: PropTypes.bool,
  t: PropTypes.func.isRequired,
  user: PropTypes.object,
  success: PropTypes.bool,
  findUser: PropTypes.func.isRequired,
  notFound: PropTypes.bool,
  searchError: PropTypes.array,
  searchRequestId: PropTypes.string,
  unblockUser: PropTypes.func.isRequired,
  unblockSuccess: PropTypes.bool,
  unblockUserError: PropTypes.array,
  unblockUserRequestId: PropTypes.string,
  emailQueryString: PropTypes.string,
};

UnblockUser.defaultProps = {
  isFetching: false,
  user: null,
  success: false,
  notFound: false,
  searchError: null,
  searchRequestId: '',
  unblockSuccess: false,
  unblockUserError: null,
  unblockUserRequestId: '',
  emailQueryString: null,
};

export const mapStateToProps = state => ({
  user: state.userSearchReducer.userModel,
  isFetching:state.userSearchReducer.isFetching || state.userUnblockReducer.isFetching,
  success: state.userSearchReducer.success,
  searchError: state.userSearchReducer.errorMessage,
  notFound: state.userSearchReducer.notFound,
  searchRequestId: state.userSearchReducer.requestId,
  emailQueryString: state.locationReducer.email,
  unblockSuccess: state.userUnblockReducer.unblockSuccess,
  unblockUserError: state.userUnblockReducer.errorMessage,
  unblockUserRequestId: state.userUnblockReducer.requestId,
});

const mapDispatchToProps = dispatch => ({
  findUser: bindActionCreators(searchUsers, dispatch),
  unblockUser: bindActionCreators(unblockUser, dispatch)
});
export default translate()(connect(mapStateToProps, mapDispatchToProps)(UnblockUser));
