/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:20:03
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2017-11-20 14:32:08
 */
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import changeEmailReducer from './changeEmailReducer';
import changePasswordReducer from './changePasswordReducer';
import registrationReducer from './registrationReducer';
import resendReducer from './resendReducer';
import tokenReducer from './tokenReducer';
import userGetReducer from './userGetReducer';
import userSearchReducer from './userSearchReducer';
import userUpdateReducer from './userUpdateReducer';
import userUnblockReducer from './userUnblockReducer';
import helpReducer from './helpReducer';
import locationReducer from './locationReducer';

export default combineReducers({
  changeEmailReducer,
  changePasswordReducer,
  helpReducer,
  registrationReducer,
  resendReducer,
  tokenReducer,
  userGetReducer,
  userSearchReducer,
  userUnblockReducer,
  userUpdateReducer,
  locationReducer,
  router: routerReducer,
});
